import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Kyss = () => {
  const headingbanner = {
    title: `KYSS`,
    content: `An accounting platform for MSME`,
  };

  const data = {
    images: ["kyss.webp", "kyss-2.webp"],
    firstAlt: "UI UX Mobile Screen Of Accounting Platform",
    firstTitle: "Landing Page",
    secondAlt: "Past Invoice Details",
    secondTitle: "All Invoices",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["FinTech"],
      },
      {
        title: "What we did",
        text: ["Branding", "User Research", "UI UX Designing"],
      },
      {
        title: "Platform",
        text: ["Web Portal", "Mobile Application "],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `While conducting our research to create an accounting system for KYSS we discovered we must cater to all the possible needs of the end user and build an ecosystem for them which can save time for the users.`,
          `Our aim was to create a comprehensive GST compliance and invoicing platform designed to simplify and streamline the GST-related processes for businesses and to eliminate the struggle with manual data entry, reconciliation errors, and keeping up with the frequent changes in GST regulations.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We recognised the need for a robust GST compliance solution. We did extensive research and a comprehensive understanding of our users' needs through various workflows. `,
          `After analyzing our research, we came to understand that creating various standalone micro-apps (that can be worked on individually and also can be integrated with the other apps) will be an effective way to build an accounting ecosystem for KYSS.`,
          `In addition to that, we worked on rebranding the interface and the overall appearance to enhance the user experience`,
        ],
      },
    ],
    image: ["kyss-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Supplier List",
    para: [
      `We created an overview screen that shows the status of all the suppliers along with their Taxpayer Type, GSTIN status, etc. `,
    ],
    content: {
      imageAlt: 'UI screen to check supplier list and their status',
      imageTitle: 'Supplier List',
      image: ["kyss-projectone.webp"],
      text: `This screen gives a comprehensive understanding of the status helping in smooth business flow.`,
    },
    content2: {
      imageAlt: 'UI screen to show supplier details and GST compliance score',
      imageTitle: 'Supplier Detail',
      image: ["kyss-projectone-2.webp"],
      text: "We also created an individual screen for the supplier’s GST filling details in order to take the required action without any wait.",
    },
  };

  const projectData2 = {
    title: `Mobile Responsive`,
    para: [
      `We also created a mobile responsive screen for better user accessibility and offered all the features as in the desktop version. `,
    ],
    content: {
      image: ["kyss-projecttwo.webp"],
      imageAlt: 'Mobile Responsive UI Screen to create invoice and check status',
      imageTitle: 'Mobile Responsive',
    },
  };

  const projectData3 = {
    title: "Invoices",
    para: [
      `The platform also offers a holistic feature to the users by allowing them to create an invoice and e-way bills. They can also view the status of each invoice in the invoice dashboard for improved experience. `,
    ],
    content: {
      image: [
        "kyss-projectthree.webp",
        "kyss-projectthree-2.webp",
        "kyss-projectthree-3.webp",
        "kyss-projectthree-4.webp",
        "kyss-projectthree-5.webp",
        "kyss-projectthree-6.webp",
        "kyss-projectthree-7.webp",
      ],
      firstAlt: 'UI Screen to create Invoice',
      firstTitle: 'Create Invoice',
      secondAlt: 'Past Invoice Details',
      secondTitle: 'All Invoices',
      thirdAlt: 'UI screen to create invoice and check details',
      thirdTitle: 'Invoice Details',
      fourthAlt: 'Track supplier from past searches',
      fourthTitle: 'GST look-up',
      fifthAlt: 'UI Screen to map data',
      fifthTitle: 'Mapping Data',
      sixthAlt: 'Mobile Responsive to check supplier overview and compliance status',
      sixthTitle: 'Supplier Overview',
      seventhAlt: "Reconciliation Summary, Mismatched Data's Mobile Responsive",
      seventhTitle: 'Reconciliation',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our research and analysis have led us to the realization that building a comprehensive accounting ecosystem for KYSS, catering to the needs of end users and streamlining GST-related processes, requires the development of standalone micro-apps that can be seamlessly integrated. `,
        `Our solution aims to simplify the complexities of GST compliance, eliminate manual data entry errors, and adapt to the frequently changing GST regulations, ultimately saving time and improving efficiency for businesses.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={13} />
      </div>
    </Layout>
  );
};

export default Kyss;

export const Head = () => (
  <Seo title="KYSS - Accounting Platform for MSME Case Study | Octet" description="We conducted branding, user research and UI UX Designing for a platform to simplify and streamline GST compliance process for MSMEs." />
)